<template>
  <div class="w-100 quizzes-card">
    <div class="p-2">
      <div class="d-flex align-items-center" style="gap: 3px">
        <div class="title">Quiz</div>

        <img
          v-character-image
          class="char-img"
          :src="get(quizzes, 'action_object.character.info.cropProfilePicture')"
        />
        <span style="color: white">{{ get(quizzes, 'action_object.character.info.name') }}</span>
      </div>
      <div class="d-flex justify-content-center text-center align-items-center flex-column text-white">
        <div class="desc">
          Your <strong>"{{ truncateEasy(get(quizzes, 'target.title'), 50) }}"</strong> Quiz is ready for day
          {{ get(quizzes, 'action_object.next_day') }}!
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="w-100"></div>
        <div class="w-100">
          <ion-button color="transparent" class="continue-btn" @click="openContQuizModal">Continue Quiz Now</ion-button>
        </div>
        <div class="d-flex justify-content-end w-100 align-items-center">
          <ion-button color="transparent" style="font-size: 10px" @click="skip">Skip</ion-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { markAsSkip } from '@/shared/actions/notifications';
import { truncateEasy } from '@/shared/utils/string';
const props = defineProps({
  quizzes: {
    type: Object,
    default: () => {},
  },
});

const quizzes = toRef(props, 'quizzes') as any;
const emits = defineEmits(['skip']);
const skip = async () => {
  await markAsSkip(quizzes.value.id);
  emits('skip');
};
const router = useRouter();
const openContQuizModal = () => {
  router.push({
    name: 'quizzes',
    query: {
      quizId: get(quizzes.value, 'target.id'),
      logId: get(quizzes.value, 'action_object.id'),
      charId: get(quizzes.value, 'action_object.character.id'),
    },
  });
};
</script>

<style scoped lang="sass">

.desc
  padding-bottom: 8px
  padding-top: 8px

.char-img
  height: 15px !important
  width: 15px !important
  border-radius: 20px
.quizzes-card
  background: linear-gradient(270deg, rgb(0 169 224) 0%, rgb(2 170 228) 77%, rgb(4 216 230) 100%)
  border-radius: 10px
  max-width: 280px
  min-width: 280px
  height: 129px
  font-size: 13px
  @media(max-width:500px)
    font-size: 12px
.title
  background: white
  border-radius: 8px
  font-weight: bold
  font-size: 14px
  color: #01a9e2
  width: 40px
  display: flex
  justify-content: center
  align-items: center
  height: 20px
.dark .title
  color: #01a9e2 !important
.continue-btn
  border-radius: 5px
  border: 2px solid white
  width: 100%
  font-size: 10px !important
  height: 24px
  text-transform: none
</style>
